import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';

import { 
    TextInput,
} from './AuthFormComponents';
import { 
    LoadingIndicator
} from './AuthFormComponents';
import { 
    baseEndPoint
} from '../utils';

const VerifySignupCodeComponent = ({
    handlePrevious,
    verificationNote,
    formData,
    setFormData
}) => {

    const navigate = useNavigate();

    const [code, setCode] = useState('')
    const [inputError, setInputError] = useState('')
    const [loading, setLoading] = useState(false)
    const [formSuccess, setFormSuccess] = useState('')
    const [formError, setFormError] = useState('')

    const handleChange = (e) => {
        setCode(e.target.value)
    }

    const sendSignupVerificationCode = async () => {
        let funcResponse = true

        try {
            const response = await baseEndPoint.post('/api/verify-code-and-sign-up/', {
                email: formData.email,
                code: code.trim(),
                first_name: formData.firstname,
                last_name: formData.lastname,
                password: formData.password,
            });

            setFormSuccess(response.data.message)

            // empty the code input
            setCode('')

            // empty the formdata
            setFormData({
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                confirmPassword: ""
            })
        } catch (err) {
            if (err && err.response.status === 400){
                setFormError(err.response.data.message)
            }else{
                // display the submission error if the slideIndex is 0
                setFormError('An error occurred. Please try again.');
            }

            funcResponse = false
        } finally {
            // hide the loader
            setLoading(false)

            // return the result of the probe user test
            return funcResponse
        }
    }

    const handleSubmit = async () => {
        // clear the form error if any
        setFormError('')

        if(!loading){
            // set loading to true
            setLoading(true)

            if(code.trim().length !== 5){
                setInputError("Please enter a five digit code.")
            }else{
                // empty the input error
                setInputError('')

                // request code verification
                const signedUp = await sendSignupVerificationCode()

                if(signedUp){
                    // navigate to the signin after a few seconds
                    setTimeout((()=>(
                        navigate('/signin')
                    )),2000)
                }
            }

            // set loading to false
            setLoading(false)
        }
    }

    return(
        <div className="verify-email-address-form">
            <header>
                <button 
                    className="move-to-previous-slide"
                    onClick={handlePrevious}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h2>Verify email</h2>	
            </header>
            {/*verification note*/}
            {verificationNote && (
                <p>
                    {verificationNote}
                </p>
            )}
            {/*<!--email verification code-->*/}
            <TextInput
                name="verificationCode"
                label="Enter code"
                type="text"
                id="verificationCode"
                placeholder="12345"
                handleChange={handleChange}
                isDisabled={loading}
                inputError={inputError}
            />
            {formError && (
                <div className="input-error form-error">{formError}</div>
            )}
            {formSuccess && (
                <div className="form-success">{formSuccess}</div>
            )}
            <button
                className={`green-button ${loading && 'green-button-disabled'}`}
                onClick={handleSubmit}
            >
                {loading && (<LoadingIndicator/>)}
                Sign up
            </button>
        </div>
    )
}

export default VerifySignupCodeComponent;