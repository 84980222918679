import React,{useContext} from 'react';

// context import
import {DataContext} from '../DataProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/donation_history_pane.css'

// component import
import { 
    HistoryIconSVG,
} from '../SvgDump';
import DashboardPane from './DashboardPane';
import { hypenDateFormat } from '../../utils';

const PaneContent = () => {
    const { data } = useContext(DataContext);

    return(
        <div>
            {data && (
                <div className="donation-history-pane">

                    {/*campaign titlebar*/}
                    <div className="pane-titlebar">
                        <div>
                            <HistoryIconSVG fill="#92cd00ff"/>
                            <h4>Donation History</h4>
                        </div>
                    </div>

                    <div className="pane-content">
                        {/*donation brief*/}
                        <div className="donation-history-brief">
                            <div>
                                <h3>Total donation counts</h3>
                                <div className="donation-history-brief-numbers">
                                    {data.records.donation_history.length}
                                </div>
                            </div>
                            <div>
                                <h3>Total amount donated</h3>
                                <div className="donation-history-brief-numbers">
                                    {`$${data.records.donation_history.reduce((entry, curr) => entry + parseFloat(curr.amount_donated), 0)}`}
                                </div>
                            </div>
                        </div>
                        {/*donation table*/}
                        <div className="db-pane-table-wrapper indicate-scrollable-on-hover">
                            <table className="donation-history-pane-table">
                                <thead>
                                    <tr>
                                        <th>Date of donation</th>
                                        <th>Amount donated</th>
                                        <th>Donation cause</th>
                                        <th>Payment method</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.records.donation_history.length>1 && (
                                            data.records.donation_history.map((entry,index)=>(
                                                <tr key={index}>
                                                    <td>{hypenDateFormat(entry.date_of_donation)}</td>
                                                    <td>{`$${entry.amount_donated}`}</td>
                                                    <td>{entry.donation_cause}</td>
                                                    <td>{entry.payment_method}</td>
                                                    <td>
                                                        <div className={`status-state ${entry.payment_status.toLocaleLowerCase()}`}>
                                                            <span></span>
                                                            <span>{entry.payment_status}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </table>


                            {/* displays when the record is empty*/}
                            {
                                data.records.donation_history.length === 0 && (
                                    <div
                                        className = "no-record-found"
                                        style={{padding: "20px 0px 0px 5px"}}
                                    >
                                        No record found
                                    </div>
                                )
                            }
                        </div>
                    </div>
    
                </div>
            )}  
        </div>
    )
}

const HistoryPane = () => (<DashboardPane PaneDataComponent={PaneContent}/>)


export default HistoryPane