import React,{useContext} from 'react';

// context import
import {DataContext} from '../DataProvider';
import { MenuContext } from '../MenuStateProvider';

// style import
import '../../../component_styles/dashboard/dashboard_pane/preview_pane.css'

// component import
import DashboardPane from './DashboardPane';
import { 
    CampaignIcon,
    HistoryIconSVG,
    ImpactIcon,
} from '../SvgDump';

// utility import
import { 
    hypenDateFormat
} from '../../utils';

const PaneContent = () => {
    const { data } = useContext(DataContext);
    const { switchMenuItemState } = useContext(MenuContext);

    return(
        <div>
            {data && (
                <div className="preview-pane">

                    {/* preview section */}
                    <section>
                        <div className="preview-section-tag">
                            <CampaignIcon fill="#92cd00ff"/>
                            <h4>Fundraising Campaigns</h4>
                        </div>
    
                        {/*preview section body*/}
                        <div className="preview-section-body">
                            <h2>Heartful Campaign</h2>
                            <div className="db-pane-table-wrapper indicate-scrollable-on-hover">
                                {/*campaign table*/}
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Campaign title</th>
                                            <th>Goal</th>
                                            <th>Amount raised</th>
                                            <th>No. of contributors</th>
                                            <th>Registration status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.records.campaigns.slice(0,3).map((data,index) => (
                                                <tr key={index}>
                                                    <td>{data.title}</td>
                                                    <td>{`$${parseFloat(data.target_amount).toLocaleString('en-US')}`}</td>
                                                    <td>{`$${parseFloat(data.amount_raised).toLocaleString('en-US')}`}</td>
                                                    <td>{data.no_of_contributors}</td>
                                                    <td
                                                        style={ data.registration_status ? {color: "#06ff00ff"}:{color: "#565a4aff"}}
                                                    >
                                                        {data.registration_status?'Registered':'Unregistered'}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/*campaign see more*/}    
                            <div 
                                className="preview-section-see-more"
                                onClick={()=>{
                                    switchMenuItemState('fundraising-campaign-trigger')
                                }}
                            >
                                <button>See more...</button>
                            </div>
                        </div>
                    </section>
    
                    {/* history section*/}
                    <section>
                        <div className="preview-section-tag">
                            <HistoryIconSVG fill="#92cd00ff"/>
                            <h4>Donation History</h4>
                        </div>

                        {/*history section body*/}
                        <div className="preview-section-body">

                            {/*donation history brief*/}
                            <div className="donation-history-brief">
                                <div>
                                    <h3>Total donation counts</h3>
                                    <div className="donation-history-brief-numbers">
                                        {data.records.donation_history.length}
                                    </div>
                                </div>
                                <div>
                                    <h3>Total amount donated</h3>
                                    <div className="donation-history-brief-numbers">
                                        {`$${data.records.donation_history.reduce((entry, curr) => entry + parseFloat(curr.amount_donated), 0)}`}
                                    </div>
                                </div>
                            </div>

                            {/*donation history table*/}
                            {data.records.donation_history>1 && (
                                <div className="db-pane-table-wrapper indicate-scrollable-on-hover">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date of donation</th>
                                                <th>Amount donated</th>
                                                <th>Donation cause</th>
                                                <th>Payment method</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.records.donation_history.slice(0,3).map((entry,index) => (
                                                    <tr key={index}>
                                                        <td>{hypenDateFormat(entry.date_of_donation)}</td>
                                                        <td>{`$${entry.amount_donated}`}</td>
                                                        <td>{entry.donation_cause}</td>
                                                        <td>{entry.payment_method}</td>
                                                        <td
                                                            style={ 
                                                                {
                                                                    // (Immediately Invoked Function Expression)
                                                                    color: (() => {
                                                                        const status = entry.payment_status.toLocaleLowerCase();
                                                                        return status === "completed"
                                                                            ? "#06ff00ff": status === "pending"
                                                                                ? "#F79D38": "#ce0000"; // Default color if no match
                                                                    })(),
                                                                }
                                                            }
                                                        >
                                                            {entry.payment_status}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {/*history see more*/}  
                            {data.records.donation_history>1 && (
                                <div className="preview-section-see-more">
                                    <button
                                        onClick={()=>{
                                            switchMenuItemState('donation-history-trigger')
                                        }}
                                    >
                                        See more...
                                    </button>
                                </div>
                            )}  
                        </div>

                    </section>

                    {/*impact updates*/}
                    <section>
                        <div className="preview-section-tag">
                            <ImpactIcon fill="#92cd00ff"/>
                            <h4>Impact updates</h4>
                        </div>

                        {/* impact section body*/}
                        <div className="preview-section-body">
                            <h2>Insightful Impacts</h2>

                            {/*preview impact cards*/}
                            <div className="preview-impact-cards">
                                {
                                    data.records.impact_updates.slice(0,3).map((data, index)=>(
                                        <div key={index} className="preview-impact-card">
                                            <div className="preview-impact-card-thumbnail">
                                                <img
                                                    src={data.image_url}
                                                    alt="card cover"
                                                />
                                            </div>
                                            <p>
                                                {
                                                    data.description.length>400?
                                                        `${data.description.slice(0,400)+'...'}` : data.description
                                                }
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>

                            {/*impact see more button*/}
                            <div className="preview-section-see-more">
                                <button
                                    onClick={()=>{
                                        switchMenuItemState('impact-updates-trigger')
                                    }}
                                >
                                    See more...
                                </button>
                            </div>
                        </div>
                    </section>

                </div>
            )}  
        </div>
    )
}

const PreviewPane = () => (<DashboardPane PaneDataComponent={PaneContent}/>)

export default PreviewPane